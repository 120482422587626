import React, { Fragment, useEffect } from 'react'
import '../styles/css/playground.css'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import Dashboard from '../routes/Dashboard'
import Contacts from '../routes/Contacts'
import Users from '../routes/Users'
import Settings from '../routes/Settings'
import Profile from '../routes/Profile'
import { setTab } from '../state/actions/tabAction'
// import Files from '../routes/Files'
import NewProduct from '../routes/NewProd'
import Notifications from '../routes/Notifications'
import Footer from './Footer'
import Products from '../routes/Products'
import Categories from '../routes/Categories'
import Orders from '../routes/Orders'
import Admins from '../routes/Admins'

const Playground = () => {
  const dispatch = useAppDispatch()
  const tab = useAppSelector((state) => state.tab)
  useEffect(() => {
    const persistedStateJSON = localStorage.getItem('reduxState')
    if (persistedStateJSON) {
      const persistedState = JSON.parse(persistedStateJSON)
      if (persistedState) {
        dispatch(setTab(persistedState.tab))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      <div className="playground">
        {tab === 'dashboard' ? (
          <Fragment>
            <Dashboard />
            <Footer />
          </Fragment>
        ) : tab === 'newprod' ? (
          <Fragment>
            <NewProduct />
            <Footer />
          </Fragment>
        ) : tab === 'products' ? (
          <Fragment>
            <Products />
            <Footer />
          </Fragment>
        ) : tab === 'categories' ? (
          <Fragment>
            <Categories />
            <Footer />
          </Fragment>
        ) : tab === 'orders' ? (
          <Fragment>
            <Orders />
            <Footer />
          </Fragment>
        ) : tab === 'contact' ? (
          <Fragment>
            <Contacts />
            <Footer />
          </Fragment>
        ) : tab === 'users' ? (
          <Fragment>
            <Users />
            <Footer />
          </Fragment>
        ) : tab === 'settings' ? (
          <Fragment>
            <Settings />
            <Footer />
          </Fragment>
        ) : tab === 'profile' ? (
          <Fragment>
            <Profile />
            <Footer />
          </Fragment>
        ) : tab === 'administrators' ? (
          <Fragment>
            <Admins />
            <Footer />
          </Fragment>
        ) : tab === 'notifications' ? (
          <Fragment>
            <Notifications />
            <Footer />
          </Fragment>
        ) : null}
      </div>
    </Fragment>
  )
}

export default Playground
