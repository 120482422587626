import React, { Fragment, useState } from 'react'
import '../styles/css/sidebar.css'
import { ReactComponent as Bars } from '../assets/svg/bars.svg'
import { ReactComponent as Xmark } from '../assets/svg/xmark.svg'
import { ReactComponent as Home } from '../assets/svg/home.svg'
import { ReactComponent as Plus } from '../assets/svg/plus.svg'
import { ReactComponent as Contact } from '../assets/svg/message.svg'
import { ReactComponent as Files } from '../assets/svg/files.svg'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Gear } from '../assets/svg/gear.svg'
import { ReactComponent as Products } from '../assets/svg/boxes-stacked.svg'
import { ReactComponent as Orders } from '../assets/svg/cart-shopping.svg'
import { ReactComponent as Admins } from '../assets/svg/administrator.svg'
import { useAppDispatch } from '../state/hooks'
import { setTab } from '../state/actions/tabAction'

const Sidebar = () => {
  const [sidewidth, setSidewidth] = useState(true)
  //current tab
  const dispatch = useAppDispatch()

  return (
    <Fragment>
      <div className={sidewidth ? 'sidebar' : 'sidebar sidemin'}>
        <div className="sidebTop">
          {sidewidth ? (
            <Xmark
              className="sbBarsIc"
              onClick={() => setSidewidth(!sidewidth)}
            />
          ) : (
            <Bars
              className="sbBarsIc"
              onClick={() => setSidewidth(!sidewidth)}
            />
          )}
        </div>
        <div className="sidebarnavigation">
          <div className="sidebarnavtop">
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('dashboard'))
              }}
            >
              <div className="sbNavIcContainer">
                <Home className="sbNavIc" />
              </div>
              <span>Home</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('newprod'))
              }}
            >
              <div className="sbNavIcContainer">
                <Plus className="sbNavIc" />
              </div>
              <span>New Product</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('products'))
              }}
            >
              <div className="sbNavIcContainer">
                <Products className="sbNavIc" />
              </div>
              <span>Products</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('categories'))
              }}
            >
              <div className="sbNavIcContainer">
                <Files className="sbNavIc" />
              </div>
              <span>Categories</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('orders'))
              }}
            >
              <div className="sbNavIcContainer">
                <Orders className="sbNavIc" />
              </div>
              <span>Orders</span>
            </div>
            {/* <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('files'))
              }}
            >
              <div className="sbNavIcContainer">
                <Files className="sbNavIc" />
              </div>
              <span>Files</span>
            </div> */}
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('users'))
              }}
            >
              <div className="sbNavIcContainer">
                <User className="sbNavIc" />
              </div>
              <span>Users</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('contact'))
              }}
            >
              <div className="sbNavIcContainer">
                <Contact className="sbNavIc" />
              </div>
              <span>Contacts</span>
            </div>
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('administrators'))
              }}
            >
              <div className="sbNavIcContainer">
                <Admins className="sbNavIc" />
              </div>
              <span>Administrators</span>
            </div>
          </div>
          <div className="sidebarnavbottom">
            <div
              className="sidebarnavlink"
              onClick={() => {
                dispatch(setTab('settings'))
              }}
            >
              <div className="sbNavIcContainer">
                <Gear className="sbNavIc" />
              </div>
              <span>Settings</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Sidebar
