// Function to check image dimensions before upload
export const checkImageDimensions = (
  file: File,
  minWidth: number,
  minHeight: number,
  maxWidth: number,
  maxHeight: number,
  aspectRatioTolerance: number
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const image = new Image()
      image.onload = () => {
        const aspectRatio = image.width / image.height
        const expectedAspectRatio = minWidth / minHeight
        const aspectRatioDeviation = Math.abs(aspectRatio - expectedAspectRatio)

        console.log(image.width, minWidth)
        console.log(image.height, minHeight)
        console.log(image.width, maxWidth)
        console.log(image.height, maxHeight)
        console.log(aspectRatioDeviation, aspectRatioTolerance)

        if (
          image.width >= minWidth &&
          image.height >= minHeight &&
          image.width <= maxWidth &&
          image.height <= maxHeight &&
          aspectRatioDeviation <= aspectRatioTolerance
        ) {
          // Image dimensions are within acceptable limits
          resolve(true)
        } else {
          // Image dimensions are not within acceptable limits
          resolve(false)
        }
      }

      // Set image source from file reader result
      if (event.target && event.target.result) {
        image.src = event.target.result.toString()
      }
    }

    // Read the file as a data URL
    reader.readAsDataURL(file)

    // Handle errors
    reader.onerror = (error) => {
      reject(error)
    }
  })
}
