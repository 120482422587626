import React, { Fragment } from 'react'
import '../styles/css/dashboard.css'
import { ReactComponent as Phone } from '../assets/svg/phone.svg'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Lightning } from '../assets/svg/lightning.svg'
import { ReactComponent as Ellipsis } from '../assets/svg/ellipsis.svg'
import LineChart from '../components/LineChart'
import PieChart from '../components/PieChart'
import DoughnutChart from '../components/DougnutChart'

const Dashboard = () => {
  const data = [10, 20, 30, 40, 50, 55, 34, 56, 78, 34, 23, 56]
  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Dec',
  ]
  const pidata = [30, 20, 40]
  const pilabels = ['Arts', 'Jewelery', 'Gift Hampers']
  return (
    <Fragment>
      <div className="dashboard">
        <div className="welcome">
          <h2>
            welcome to <br />
            Gro & MedMart <span>admin</span>
          </h2>
          <div className="welRight">
            <p>showing data for:</p>
            <div className="calrangecont">
              <input
                className="calrange"
                id="CRMDateRange"
                type="text"
                data-options='{"mode":"range","dateFormat":"M d","disableMobile":true , "defaultDate": ["Sep 12", "Sep 19"] }'
              />
            </div>
          </div>
        </div>
        <div className="genstats">
          <div className="genscontact">
            <div className="top">
              <div className="tIc">
                <Phone className="tlcIc" />
                <span>new contact</span>
              </div>
              <Ellipsis className="ellIc" />
            </div>
            <div className="bottom"></div>
          </div>
          <div className="gensnewusrs">
            <div className="top">
              <div className="tIc">
                <User className="tlcIc" />
                <span>new users</span>
              </div>
              <Ellipsis className="ellIc" />
            </div>
            <div className="bottom"></div>
          </div>
          <div className="gensnewleads">
            <div className="top">
              <div className="tIc">
                <Lightning className="tlcIc" />
                <span>new leads</span>
              </div>
              <Ellipsis className="ellIc" />
            </div>
            <div className="bottom"></div>
          </div>
        </div>
        <div className="dashcharts">
          <div className="linechart">
            <LineChart data={data} labels={labels} />
          </div>
          <div className="piechart">
            <PieChart data={pidata} labels={pilabels} />
          </div>
          <div className="dashInfolytics">
            <DoughnutChart data={pidata} labels={pilabels} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Dashboard
