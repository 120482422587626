import React from 'react'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

SwiperCore.use([Navigation, Pagination])

interface Props {
  images: (string | File)[]
  deleteImage: (value: any) => void
}

const Slider: React.FC<Props> = ({ images, deleteImage }) => {
  images = images.filter((i) => typeof i === 'string')
  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
      slidesPerView={1}
      pagination={{
        clickable: true,
      }}
      freeMode={false}
      shortSwipes={false}
      longSwipes={false}
      style={{ alignItems: 'center' }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={image as string}>
          <div style={{ position: 'relative' }}>
            <Trash
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                height: '20px',
                width: '20px',
                cursor: 'pointer',
                fill: 'red',
              }}
              onClick={() => {
                deleteImage(image)
              }}
            />
            <img
              src={image instanceof File ? '' : image}
              alt={`Slide ${index}`}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slider
