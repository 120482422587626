import React, { FormEvent, useEffect, useState } from 'react'
import '../styles/css/categories.css'
import { ReactComponent as Xmark } from '../assets/svg/xmark.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

interface MiniTypes {
  title: string
  slug: string
}

interface subCats {
  title: string
  slug: string
  categories: MiniTypes[]
}

interface DataTypes {
  category: string
  sub_categories: subCats[]
}

const Categories = () => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState<DataTypes>({
    category: '',
    sub_categories: [],
  })
  const [subcat, setSubCat] = useState<subCats>({
    title: '',
    slug: '',
    categories: [],
  })
  const [minicategory, setMiniCategory] = useState<MiniTypes>({
    title: '',
    slug: '',
  })
  const [categories, setCategories] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  console.log(data)

  const handleSubmitCats = async (e: FormEvent) => {
    e.preventDefault()
    const res = await api('POST', 'categories/add', data)
    if (res.status >= 200 && res.status <= 299) {
      toast(res.data.message, { type: 'success' })
      setData({
        category: '',
        sub_categories: [],
      })
      fetchCategories()
    } else {
      toast(res.data.message, { type: 'error' })
    }
  }

  const fetchCategories = async () => {
    const res = await api('GET', 'categories/all')
    if (res.status >= 200 && res.status <= 299) {
      console.log(res.data)

      setCategories(res.data)
    } else {
      toast(res.data.message, { type: 'error' })
    }
  }
  useEffect(() => {
    fetchCategories()
  }, [])
  return (
    <div className="categories">
      <div className="categories_container">
        {modal && (
          <div
            className="categories_add_form"
            onClick={() => {
              setModal(false)
            }}
          >
            <form
              className="cat_form_add"
              onSubmit={(e) => {
                handleSubmitCats(e)
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Xmark
                className="close_category_modal"
                onClick={() => {
                  setModal(false)
                }}
              />
              <h1 className="cat_modal_title">Add a Category</h1>
              <div className="form_group">
                <label htmlFor="Category">
                  Category <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="category_input"
                  name="category"
                  value={data.category}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                />
              </div>
              <div className="form_group">
                <div className="form_group_swiper">
                  <div className="subcategories_list">
                    {Array.isArray(data.sub_categories) &&
                      data.sub_categories.map((category) => (
                        <span
                          key={category.title + '_' + uuidv4()}
                          className="subcategories_list_item"
                        >
                          {category.title} <Xmark className="close_sub_cat" />
                        </span>
                      ))}
                  </div>
                  {Array.isArray(data.sub_categories) && (
                    <div className="sub_cat_flexxer">
                      <input
                        type="text"
                        className="parent_sub_category"
                        placeholder="add subcategory"
                        onChange={(e) => {
                          setSubCat({
                            title: e.target.value,
                            slug: '',
                            categories: [],
                          })
                        }}
                      />
                      <button
                        type="button"
                        className="parent_sub_category_btn"
                        onClick={() => {
                          const isDuplicate = data.sub_categories.some(
                            (category) => category.title === subcat.title
                          )
                          if (!isDuplicate) {
                            setData({
                              ...data,
                              sub_categories: [...data.sub_categories, subcat],
                            })
                            setSubCat({ title: '', slug: '', categories: [] })
                          } else {
                            toast(
                              `Cannot enter duplicate Entry! ${subcat.title}`,
                              {
                                type: 'error',
                              }
                            )
                          }
                        }}
                      >
                        add
                      </button>
                    </div>
                  )}
                </div>
                <div className="slides_carousel" style={{ marginTop: '10px' }}>
                  {Array.isArray(data.sub_categories) &&
                    data.sub_categories.map((category, index) => (
                      <div
                        className={
                          index === currentIndex
                            ? 'slide active_slider'
                            : 'slide'
                        }
                        key={index}
                      >
                        <h1 style={{ fontSize: '1rem', marginBottom: '10px' }}>
                          {category.title}
                        </h1>
                        {data.sub_categories.map((item) => {
                          return item.title === category.title
                            ? item.categories.map((i) => (
                                <span
                                  key={i.title + '_' + uuidv4()}
                                  className="subcategories_list_item"
                                >
                                  {i.title} <Xmark className="close_sub_cat" />
                                </span>
                              ))
                            : null
                        })}
                        <div
                          className="sub_cat_flexxer"
                          style={{ marginTop: '5px' }}
                        >
                          <input
                            type="text"
                            className="parent_sub_category"
                            placeholder={`add subcategory to ${category.title}`}
                            onChange={(e) => {
                              setMiniCategory({
                                title: e.target.value,
                                slug: '',
                              })
                            }}
                          />
                          <button
                            type="button"
                            className="parent_sub_category_btn"
                            onClick={() => {
                              data.sub_categories.map((item) => {
                                if (item.title === category.title) {
                                  const matched_obj = item.categories.some(
                                    (it) => it.title === minicategory.title
                                  )
                                  if (
                                    !matched_obj &&
                                    minicategory.title !== ''
                                  ) {
                                    const remove = data.sub_categories.filter(
                                      (i) => i.title !== item.title
                                    )
                                    setData({
                                      ...data,
                                      sub_categories: [
                                        ...remove,
                                        {
                                          ...item,
                                          categories: [
                                            ...item.categories,
                                            minicategory,
                                          ],
                                        },
                                      ],
                                    })
                                  } else {
                                    toast('cannot Enter Duplicate Entry', {
                                      type: 'error',
                                    })
                                  }
                                }
                                return null
                              })

                              setMiniCategory({
                                title: '',
                                slug: '',
                              })
                            }}
                          >
                            add
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="cat_slider_controls">
                <button
                  className="slider_category_control"
                  type="button"
                  onClick={() => {
                    setCurrentIndex((prevIndex) =>
                      prevIndex === data.sub_categories.length - 1
                        ? 0
                        : prevIndex + 1
                    )
                  }}
                >
                  Previous
                </button>
                <button
                  className="slider_category_control"
                  type="button"
                  onClick={() => {
                    setCurrentIndex((prevIndex) =>
                      prevIndex === 0
                        ? data.sub_categories.length - 1
                        : prevIndex - 1
                    )
                  }}
                >
                  Next
                </button>
              </div>
              <div className="btn_cat_add_wrapper">
                <button type="submit" className="btn_cat_add">
                  add category
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="categories_wrapper">
          <div className="categories_top">
            <h1 className="categories_tab_title">Categories</h1>
            <button
              className="category_add"
              onClick={() => {
                setModal(true)
              }}
            >
              Add Category
            </button>
          </div>
          <table className="categories_table">
            <thead>
              <tr
                style={{
                  backgroundColor: '#ff4380',
                  color: '#fff',
                  fontWeight: 'bold',
                }}
              >
                <td>Category</td>
                <td>Sub Categories</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {categories &&
                Array.isArray(categories) &&
                categories.map((category: any) => (
                  <tr>
                    <td>{category.title}</td>
                    <td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {category.sub_categories.map((item: any) => (
                        <div
                          style={{
                            borderRight: '1px solid #000',
                            paddingRight: '10px',
                          }}
                        >
                          <span>
                            {item.sub_title}
                            <Xmark className="remove_category" />
                          </span>
                          <div
                            className="mini_cats_list"
                            style={{
                              display: 'flex',
                              gap: '10px',
                              marginTop: '10px',
                            }}
                          >
                            {item.categories.map((i: any) => (
                              <span style={{ backgroundColor: '#5904bb' }}>
                                {i.mini_title}
                                <Xmark className="remove_category" />
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      <div className="actions-wrapp">
                        <Trash className="report-action" />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Categories
